import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { staticStyle: { "padding-bottom": "34px" } },
    [
      _vm.loading
        ? _c(VLayout, { attrs: { "justify-center": "" } }, [
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  VContainer,
                  { attrs: { "grid-list-xl": "" } },
                  [
                    _c(
                      VLayout,
                      {
                        staticStyle: { height: "45vh" },
                        attrs: {
                          column: "",
                          "justify-center": "",
                          "fill-height": "",
                          "align-center": ""
                        }
                      },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            size: 90,
                            width: 9,
                            color: "primary",
                            indeterminate: ""
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : !_vm.isMenuSchedulingEnabled
        ? _c(
            VLayout,
            {
              attrs: {
                "align-center": "",
                "justify-center": "",
                row: "",
                wrap: ""
              }
            },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(VImg, {
                    staticClass: "mt-5 mb-3",
                    attrs: {
                      src: require("@/assets/menu-no-operation-hours.svg"),
                      scale: 1,
                      contain: true,
                      height: "350px"
                    }
                  })
                ],
                1
              ),
              _c(VFlex, { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "Body-1-Black-High-Emphasis-Center" },
                  [_vm._v("Please assign Service or Delivery hours")]
                )
              ]),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VLayout,
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mt-4",
                          attrs: { color: "primary rounded m-2" },
                          on: {
                            click: function($event) {
                              return _vm.$router.replace("pickup")
                            }
                          }
                        },
                        [_vm._v("Go to operation hours")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : !_vm.hasMenu
        ? _c(
            VLayout,
            {
              attrs: {
                "justify-center": "",
                "align-center": "",
                row: "",
                wrap: ""
              }
            },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(VImg, {
                    staticClass: "mt-5 mb-3",
                    attrs: {
                      src: require("@/assets/no-brand-menu-picture.svg"),
                      scale: 1,
                      contain: true,
                      height: "350px"
                    }
                  })
                ],
                1
              ),
              _c(VFlex, { staticClass: "mt-4 ml-4", attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "Body-1-Black-High-Emphasis-Center" },
                  [_vm._v(_vm._s(_vm.message))]
                )
              ])
            ],
            1
          )
        : _c(
            VLayout,
            { attrs: { "justify-space-between": "", row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { staticClass: "mb-4 ml-2", attrs: { xs12: "" } },
                [
                  _c(
                    VLayout,
                    {
                      staticClass:
                        "mt-4 ml-4 H6-Selected-On-Surface-High-Emphasis-Left"
                    },
                    [_vm._v("Schedule Your Menus")]
                  ),
                  _c(
                    VLayout,
                    { attrs: { "justify-space-between": "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { md6: "", xs9: "" } },
                        [
                          _c(VTextField, {
                            staticClass: "ma-3",
                            attrs: {
                              repeatLabel:
                                "Search Menus in " +
                                (_vm.active_brand && _vm.active_brand.name),
                              "single-line": "",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: ""
                            },
                            model: {
                              value: _vm.filter,
                              callback: function($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter"
                            }
                          })
                        ],
                        1
                      ),
                      _c(VFlex, { attrs: { xs3: "" } }, [
                        _c(
                          "div",
                          { staticClass: "FAB-Extended-PrimaryColor-Enabled" },
                          [
                            _vm.canWriteMenuSchedule
                              ? _c(
                                  VBtn,
                                  {
                                    staticClass: "mt-4",
                                    attrs: { color: "primary rounded m-2" },
                                    on: { click: _vm.goToMenuScheduler }
                                  },
                                  [
                                    _c(
                                      VIcon,
                                      { attrs: { left: "", dark: "" } },
                                      [_vm._v("mdi-plus")]
                                    ),
                                    _vm._v("Schedule menu\n            ")
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.loading
                ? _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        VDataTable,
                        {
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.menuEvents,
                            "hide-actions": "",
                            search: _vm.filter,
                            pagination: _vm.pagination
                          },
                          on: {
                            "update:pagination": function($event) {
                              _vm.pagination = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "items",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "tr",
                                      {
                                        style:
                                          "cursor:" +
                                          (_vm.canReadMenuSchedule
                                            ? "pointer"
                                            : "initial")
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-xs-left",
                                            staticStyle: {
                                              "max-width": "300px !important"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goToMenuScheduler(
                                                  props.item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(props.item.name) +
                                                "\n              "
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "menu-tag",
                                                class: _vm.determineMenuTagClass(
                                                  props.item.menuId
                                                )
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.determineMenuVersion(
                                                        props.item.menuId
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-xs-left",
                                            staticStyle: {
                                              "max-width": "310px !important"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goToMenuScheduler(
                                                  props.item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "Body-1-Black-High-Emphasis-Left"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item.schedule.hours
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "Body-2-Selected-On-Surface-Medium-Emphasis-Left"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      props.item.schedule.repeat
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "Body-2-Selected-On-Surface-Medium-Emphasis-Left"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      props.item.schedule.range
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-xs-left",
                                            staticStyle: {
                                              "max-width": "300px !important"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goToMenuScheduler(
                                                  props.item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(props.item.serviceType) +
                                                "\n            "
                                            )
                                          ]
                                        ),
                                        _vm.checkVersion("1.2")
                                          ? _c(
                                              "td",
                                              { staticClass: "noselect" },
                                              [
                                                _c(
                                                  VTooltip,
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "div",
                                                                _vm._g(
                                                                  {
                                                                    staticStyle: {
                                                                      width:
                                                                        "fit-content"
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    VCheckbox,
                                                                    {
                                                                      attrs: {
                                                                        "hide-details":
                                                                          "",
                                                                        "on-icon":
                                                                          "mdi-eye",
                                                                        "off-icon":
                                                                          "mdi-eye-off",
                                                                        color:
                                                                          "none",
                                                                        disabled: _vm.isMenuToggleDisabled(
                                                                          props.item
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.setMenuStatus(
                                                                            $event,
                                                                            props.item
                                                                          )
                                                                        }
                                                                      },
                                                                      nativeOn: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          props
                                                                            .item
                                                                            .status,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            props.item,
                                                                            "status",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "props.item.status"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    props.item.status
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "Display in App"
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            "Hidden in App"
                                                          )
                                                        ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            702387995
                          )
                        },
                        [
                          _c(VProgressLinear, {
                            attrs: {
                              color: "blue",
                              indeterminate: "",
                              height: "7"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "progress",
                                  fn: function() {
                                    return undefined
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              63074868
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }