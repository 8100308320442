<template>
  <v-layout style="padding-bottom: 34px;">
    <v-layout v-if="loading" justify-center>
      <div class="mt-5">
        <v-container grid-list-xl>
          <v-layout column justify-center fill-height align-center style="height: 45vh;">
            <v-progress-circular
              :size="90"
              :width="9"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-layout>
        </v-container>
      </div>
    </v-layout>
    <v-layout v-else-if="!isMenuSchedulingEnabled" align-center justify-center row wrap>
      <v-flex xs12>
        <v-img
          :src="require('@/assets/menu-no-operation-hours.svg')"
          :scale="1"
          :contain="true"
          class="mt-5 mb-3"
          height="350px"
        />
      </v-flex>
      <v-flex xs12>
        <div class="Body-1-Black-High-Emphasis-Center">Please assign Service or Delivery hours</div>
      </v-flex>
      <v-flex xs12>
        <v-layout justify-center>
          <v-btn class="mt-4" color="primary rounded m-2" @click="$router.replace('pickup')"
            >Go to operation hours</v-btn
          >
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout justify-center align-center v-else-if="!hasMenu" row wrap>
      <v-flex xs12>
        <v-img
          :src="require('@/assets/no-brand-menu-picture.svg')"
          :scale="1"
          :contain="true"
          class="mt-5 mb-3"
          height="350px"
        />
      </v-flex>
      <v-flex xs12 class="mt-4 ml-4">
        <div class="Body-1-Black-High-Emphasis-Center">{{ message }}</div>
      </v-flex>
    </v-layout>
    <v-layout justify-space-between row wrap v-else>
      <v-flex xs12 class="mb-4 ml-2">
        <v-layout class="mt-4 ml-4 H6-Selected-On-Surface-High-Emphasis-Left"
          >Schedule Your Menus</v-layout
        >
        <v-layout justify-space-between>
          <v-flex md6 xs9>
            <v-text-field
              :repeatLabel="`Search Menus in ${active_brand && active_brand.name}`"
              single-line
              prepend-inner-icon="mdi-magnify"
              clearable
              class="ma-3"
              v-model="filter"
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <div class="FAB-Extended-PrimaryColor-Enabled">
              <v-btn
                class="mt-4"
                color="primary rounded m-2"
                v-if="canWriteMenuSchedule"
                @click="goToMenuScheduler"
              >
                <v-icon left dark>mdi-plus</v-icon>Schedule menu
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-if="!loading">
        <v-data-table
          :headers="headers"
          :items="menuEvents"
          hide-actions
          :search="filter"
          :pagination.sync="pagination"
        >
          <v-progress-linear
            v-slot:progress
            color="blue"
            indeterminate
            height="7"
          ></v-progress-linear>
          <template v-slot:items="props">
            <tr :style="`cursor:${canReadMenuSchedule ? 'pointer' : 'initial'}`">
              <td
                style="max-width: 300px !important;"
                class="text-xs-left"
                @click="goToMenuScheduler(props.item)"
              >
                {{ props.item.name }}
                <div class="menu-tag" :class="determineMenuTagClass(props.item.menuId)">
                  {{ determineMenuVersion(props.item.menuId) }}
                </div>
              </td>
              <td
                style="max-width: 310px !important;"
                class="text-xs-left"
                @click="goToMenuScheduler(props.item)"
              >
                <div class="Body-1-Black-High-Emphasis-Left">{{ props.item.schedule.hours }}</div>
                <div class="Body-2-Selected-On-Surface-Medium-Emphasis-Left">
                  {{ props.item.schedule.repeat }}
                </div>
                <div class="Body-2-Selected-On-Surface-Medium-Emphasis-Left">
                  {{ props.item.schedule.range }}
                </div>
              </td>
              <td
                style="max-width: 300px !important;"
                class="text-xs-left"
                @click="goToMenuScheduler(props.item)"
              >
                {{ props.item.serviceType }}
              </td>
              <td class="noselect" v-if="checkVersion('1.2')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" style="width: fit-content;">
                      <v-checkbox
                        v-on:change="setMenuStatus($event, props.item)"
                        v-model="props.item.status"
                        hide-details
                        on-icon="mdi-eye"
                        off-icon="mdi-eye-off"
                        color="none"
                        @click.native.stop
                        :disabled="isMenuToggleDisabled(props.item)"
                      />
                    </div>
                  </template>
                  <span v-if="props.item.status">Display in App</span>
                  <span v-else>Hidden in App</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import DateTime from 'luxon/src/datetime.js';
import { CalendarTypes, RecurrenceTypes, UserRoles } from '@/constants';
import menuSchedulerHelper from './menuScheduler/helper';

export default {
  name: 'Info',
  data: () => ({
    stationMenus: null,
    filter: null,
    pagination: { sortBy: 'name', sort: 'descending', rowsPerPage: -1 },
    message: 'No menus for this brand exist in Menu Manager',
    isUpdatingCalendar: false,
    input: null,
    preloadedScheduledMenusMap: new Map(),
  }),
  computed: {
    ...mapGetters('calendar', { getEvents: 'getEvents' }),
    ...mapState('sites', ['active_brand', 'brandMap']),
    ...mapState('users', ['customPermissions']),
    ...mapState('calendar', ['events', 'isActiveBrandCalendarFetched']),
    ...mapGetters('adminPanel', ['userHasAnyRole', 'hasSpecificPermissions']),
    ...mapGetters('menus', ['getMenusArray']),
    ...mapGetters('sites', {
      isDHBrand: 'isDHBrand',
    }),
    ...mapGetters('centricOSMenu', ['isCentricOSIntegrated']),
    menusLoaded() {
      return this.getMenusArray?.length > 0;
    },
    headers() {
      const headers = [
        {
          text: 'Menu',
          align: 'left',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Service Hours (Local Time)',
          align: 'left',
          value: 'schedule',
          sortable: false,
        },
        {
          text: 'Service Type',
          align: 'left',
          value: 'serviceType',
          sortable: false,
        },
      ];
      if (this.checkVersion('1.2')) {
        headers.push({
          text: 'Menu Status',
          align: 'left',
          value: 'status',
          sortable: false,
        });
      }
      return headers;
    },
    isMenuSchedulingEnabled() {
      const isCentricOSStation = Boolean(this.active_brand?.is?.cafe360);
      if (isCentricOSStation) return true;
      if (!this.events || !this.events.length) return false;

      const events = [
        ...this.getEvents(CalendarTypes.business),
        ...this.getEvents(CalendarTypes.delivery),
      ];

      return events.length > 0;
    },
    hasMenu() {
      if (
        !this.stationMenus ||
        !this.stationMenus.length ||
        !this.active_brand ||
        !this.active_brand.id
      )
        return false;
      return this.stationMenus.length > 0;
    },
    menuEvents() {
      if (!this.stationMenus || !this.stationMenus.length) return [];
      const events = this.getEvents(CalendarTypes.menu).map((m) => {
        const { data } = m;
        const menuDetails = this.stationMenus.filter((e) => e.id === data.menu)[0];
        return {
          name: data.title || '',
          schedule: this.getSchedule(m),
          serviceType: this.getServiceType(data),
          menuId: data.menu || null,
          status: !(data.is && data.is.disabled),
          event: m,
          menuEvent: menuSchedulerHelper.convertEventToMenuEvent(m, this.active_brand.timezone),
          isGlobalMenuDisabled: menuDetails?.is?.disabled || false,
          menuNotFound: Boolean(!menuDetails),
        };
      });

      return events;
    },
    loading() {
      return !this.stationMenus || !this.isActiveBrandCalendarFetched;
    },
    canReadMenuSchedule() {
      const hasHighPermissionRole = this.userHasAnyRole([UserRoles.admin, UserRoles.sys_admin]);
      const hasSpecificPermission = this.hasSpecificPermissions([
        this.customPermissions.allowReadSchedule,
      ]);
      return hasHighPermissionRole || hasSpecificPermission;
    },
    canWriteMenuSchedule() {
      const hasHighPermissionRole = this.userHasAnyRole([UserRoles.admin, UserRoles.sys_admin]);
      const hasSpecificPermission = this.hasSpecificPermissions([
        this.customPermissions.allowSchedule,
      ]);
      return hasHighPermissionRole || hasSpecificPermission;
    },
  },
  watch: {
    menusLoaded: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.preloadedScheduledMenusMap.size === 0) {
          this.preloadScheduledMenus();
        }
      },
    },
    active_brand: {
      async handler(brand, oldValue) {
        if (
          !brand ||
          !brand.id ||
          brand.id !== this.$route.params.brand_id ||
          brand.id === (oldValue && oldValue.id)
        )
          return;
        this.$store.commit('adminPanel/setViewTitle', {
          depth: 2,
          title: brand.name,
          to: {
            name: 'brand-overview-menus',
            params: { site_id: this.$route.params.site_id, brand_id: this.$route.params.brand_id },
          },
        });
        if (this.isDHBrand(brand)) {
          this.stationMenus = [];
          this.message = 'Unable to schedule menus for DH locations';
          return;
        }
        try {
          this.stationMenus = await this.getStationMenus(brand);
        } catch (error) {
          console.error(error);
          this.$toast.error('Could not fetch information, please try again');
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('calendar', ['saveEvents']),
    ...mapActions('menus', ['fetchCompanyMenu']),
    ...mapActions('sites', ['patchLocationBrand']),
    ...mapActions('centricOSMenu', ['fetchLocalBrandV2Menus']),
    preloadScheduledMenus() {
      const menuEvents = this.getEvents(CalendarTypes.menu);
      const scheduledMenuIds = [...new Set(menuEvents.map((event) => event.data.menu))];
      const allMenus = this.getMenusArray;
      const preloadedScheduledMenus = allMenus.filter((menu) => {
        return scheduledMenuIds.includes(menu.id);
      });
      // convert scheduled menus into a map for efficient lookup times
      this.preloadedScheduledMenusMap = new Map(
        preloadedScheduledMenus.map((menu) => {
          return [menu.id, menu];
        }),
      );
      return preloadedScheduledMenus;
    },
    determineMenuTagClass(menuId) {
      if (this.determineMenuVersion(menuId) === 'Admin Menu') {
        return 'admin-menu';
      }
      return 'centric-menu';
    },
    determineMenuVersion(menuId) {
      const menu = this.preloadedScheduledMenusMap.get(menuId);
      return menu?.meta?.version === 3 ? 'CentricOS Menu' : 'Admin Menu';
    },
    goToMenuScheduler(m) {
      if (!this.canReadMenuSchedule) return;
      const { name, event } = m;
      const eventId = (event && event.data && event.data.id) || undefined;
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 3,
        title: name || 'Schedule Menu',
        to: { name: `scheduler-${eventId ? 'edit' : 'new'}`, params: { event_id: eventId } },
      });
      this.$router.push({
        name: `scheduler-${eventId ? 'edit' : 'new'}`,
        params: {
          event_id: eventId,
        },
      });
    },
    formatLocaleDate(date) {
      return !date
        ? date
        : DateTime.fromISO(date || DateTime.local().toISODate()).toLocaleString(DateTime.DATE_HUGE);
    },
    getSchedule(m) {
      const menuEvent = menuSchedulerHelper.convertEventToMenuEvent(m, this.active_brand.timezone);
      const { repeat, startTime, endTime, hasEndDate, isAllDay } = menuEvent.schedule;
      let repeatLabel = repeat.type.toLowerCase();
      if (!Object.values(RecurrenceTypes).some((r) => r === repeat.type)) {
        repeatLabel = menuSchedulerHelper.getRepeatSelectionLabel({
          days: repeat.days,
          repeat: repeat.frequency,
        });
      }

      const startDate = this.formatLocaleDate(menuEvent.schedule.startDate);
      const endDate = hasEndDate ? this.formatLocaleDate(menuEvent.schedule.endDate) : '';

      return {
        hours: isAllDay
          ? 'All day'
          : `${menuSchedulerHelper.formatHour(startTime)}-${menuSchedulerHelper.formatHour(
              endTime,
            )}`,
        repeat: repeat.type === RecurrenceTypes.NeverRepeat ? '' : `repeat ${repeatLabel}`,
        range: `${repeat.type === RecurrenceTypes.NeverRepeat ? 'on' : 'from'} ${startDate}${
          hasEndDate && !!endDate ? '-' : ''
        }${endDate}`,
      };
    },
    getServiceType(data) {
      let serviceType = '';
      if (data.is) {
        if (data.is.pickup) serviceType += 'Pickup';
        if (data.is.pickup && data.is.delivery) serviceType += ' , ';
        if (data.is.delivery) serviceType += 'Delivery';
      }
      return serviceType || 'No info';
    },
    async setMenuStatus(isActive, menu) {
      this.isUpdatingCalendar = true;
      try {
        const menuEvents = this.getEvents(CalendarTypes.menu).map((m) => {
          if (m.data.id === menu.event.data.id) {
            return {
              ...m,
              data: {
                ...m.data,
                is: {
                  ...((m.data && m.data.is) || {}),
                  disabled: !isActive,
                },
              },
            };
          }
          return m;
        });
        await this.saveEvents({ events: menuEvents, type: CalendarTypes.menu });
        this.$toast(`${menu.name} is now ${isActive ? 'Active' : 'Inactive'}`);
      } catch (err) {
        console.error(err);
        this.$toast.error('Error saving Menu Status');
      }
      this.isUpdatingCalendar = false;
    },
    isMenuToggleDisabled(menuEvent) {
      const hasHighPermissionRole = this.userHasAnyRole([UserRoles.admin, UserRoles.sys_admin]);
      const hasSpecificPermission = this.hasSpecificPermissions([
        this.customPermissions.toggleMenuEnabled,
      ]);
      const hasTogglePermission = hasHighPermissionRole || hasSpecificPermission;
      return !hasTogglePermission || menuEvent.isGlobalMenuDisabled || this.isUpdatingCalendar;
    },
    async getStationMenus(station) {
      if (this.isCentricOSIntegrated(station)) {
        return this.fetchLocalBrandV2Menus(station.meta.menu?.brand);
      }
      return this.fetchCompanyMenu({ id: station.company, nocache: true }).then((r) =>
        r.menus?.filter((m) => m.location_brand === station.id),
      );
    },
  },

  async mounted() {
    this.preloadScheduledMenus();
  },
};
</script>

<style scoped>
div >>> .mdi-eye {
  color: green;
}
div >>> .mdi-eye-off {
  color: red;
}
div >>> .v-input--is-disabled .mdi-eye,
div >>> .v-input--is-disabled .mdi-eye-off {
  color: gray;
}
div >>> .headline {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.1px !important;
  color: #0d73d8;
  font-family: 'Avenir', 'Poppins', 'sans-serif' !important;
}
div >>> .v-btn {
  min-width: 225px;
}
div >>> .v-btn > .v-btn__content {
  justify-content: flex-start;
}
.menu-tag {
  display: flex;
  border-radius: 5px;
  display: block;
  width: auto;
  padding: 0px 10px;
}
.centric-menu {
  background-color: #eae6ff;
  max-width: 150px;
  text-align: center;
}
.admin-menu {
  background-color: #ffe89d;
  max-width: 120px;
  text-align: center;
}
</style>
