import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import DateTime from 'luxon/src/datetime.js';
import { RecurrenceTypes, CalendarTypes } from '@/constants';

const formatHour = (time) => {
  if (!time) return '';

  const times = time.split(':');
  let hours = Number(times[0]);
  let minutes = Number(times[1]);
  const ampm = hours % 24 < 12 ? 'am' : 'pm';

  if (minutes < 10) {
    minutes = `0${minutes}`; // adding leading zero
  }

  hours %= 12;
  if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${minutes}${ampm}`;
};

const getRepeatSelectionLabel = (data, differentiateLast = false, startWithUpperCase = false) => {
  const { days, repeat } = data;
  let prefix = '';
  if (repeat === 1) prefix += `${startWithUpperCase ? 'W' : 'w'}eekly on`;
  else prefix += `${startWithUpperCase ? 'E' : 'e'}very ${repeat} weeks on`;

  const selectedDays = Object.keys(days)
    .filter((d) => !!days[d])
    .map((d) => `${['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'][d]}`);
  let label = `${prefix} `;
  const numDays = selectedDays.length;
  if (numDays > 1) {
    selectedDays.forEach((d, i) => {
      if (differentiateLast) {
        if (i < numDays - 2) {
          label += `${d}, `;
        } else if (i === numDays - 2) {
          label += `${d} and `;
        } else {
          label += `${d}`;
        }
      } else if (i < numDays - 1) label += `${d}, `;
      else label += `${d}`;
    });
  } else label += `${selectedDays.join('')}`;
  return label;
};

const validDaysCount = (days) => Object.keys(days).filter((d) => !!days[d]).length;

const isValidRecurrence = (menuEvent) => (v) => {
  const { repeat } = menuEvent.schedule;
  const type = (v && v.trim()) || '';
  if (Object.keys(RecurrenceTypes).some((r) => r === type)) {
    return true;
  }

  // custom recurrence, check if valid
  const recurrenceLabel = getRepeatSelectionLabel({
    repeat: repeat.frequency || '',
    days: repeat.days || {},
  });
  return recurrenceLabel === type || 'Invalid Recurrence';
};

const getDefaultMenuEvent = (isDeliveryEnabled) => {
  const defaultMenuEvent = {
    menu: null,
    schedule: {
      hasEndDate: true,
      startTime: null,
      endTime: null,
      showStartDate: false,
      showEndDate: false,
      startDate: null,
      endDate: null,
      renderTime: true,
      renderRecurrence: true,
      repeat: {
        frequency: null,
        type: null,
        days: null,
      },
      isAllDay: false,
    },
    is: {
      delivery: false,
      pickup: false,
      frictionless: false,
    },
  };
  if (!isDeliveryEnabled) defaultMenuEvent.is.pickup = true;
  return defaultMenuEvent;
};

const getDuration = (startTime, endTime) => {
  const startInfo = startTime.split(':').map((n) => parseInt(n, 10));
  const endInfo = endTime.split(':').map((n) => parseInt(n, 10));
  return (endInfo[0] - startInfo[0]) * 60 + (endInfo[1] - startInfo[1]);
};

const timeRules = (m) => {
  const { startTime, endTime, isAllDay } = m.schedule;
  const isValidTimeRange =
    isAllDay || !startTime || !endTime || getDuration(startTime, endTime) > 0;
  return [() => isValidTimeRange || 'Plese select a valid time range'];
};

const convertMenuEventToEvent = (menuEvent, menus, timezone) => {
  // map schedule
  const { repeat, hasEndDate, endDate, startTime, endTime, isAllDay } = menuEvent.schedule;
  const dateStart = DateTime.fromISO(menuEvent.schedule.startDate).setZone(timezone);
  const dateEnd = DateTime.fromISO(menuEvent.schedule.endDate).setZone(timezone);
  const { NeverRepeat, Daily, Weekday } = RecurrenceTypes;
  const schedule = {};

  if (!isAllDay) {
    const duration = getDuration(startTime, endTime);
    schedule.durationUnit = 'minutes';
    schedule.duration = duration;
    schedule.times = [startTime];
  } else {
    schedule.duration = 1;
  }

  if (repeat.type !== NeverRepeat) {
    schedule.start = dateStart.toMillis();
    if (hasEndDate && endDate) schedule.end = dateEnd.toMillis();
  }

  switch (menuEvent.schedule.repeat.type) {
    case NeverRepeat:
      schedule.dayOfMonth = [dateStart.day];
      schedule.month = [dateStart.month];
      schedule.year = [dateStart.year];
      break;
    case Daily:
      break;
    case Weekday:
      schedule.dayOfWeek = [1, 2, 3, 4, 5];
      break;
    default:
      if (!repeat) break;
      schedule.dayOfWeek = Object.keys(repeat.days)
        .filter((d) => repeat.days[d])
        .map((d) => d);
      schedule.every = { week: repeat.frequency };
      break;
  }

  // map data
  const data = {
    color: '#ff8c84',
    calendar: CalendarTypes.menu,
    is: { ...menuEvent.is },
    id: menuEvent && menuEvent.data && menuEvent.data.id,
  };

  const menu = menus.find((m) => m.value === menuEvent.menu);
  data.title = menu.label || '';
  data.menu = menu.value;
  return { data, schedule };
};

const convertEventToMenuEvent = (event, timezone) => {
  const { data, schedule } = event;

  const menuEvent = {
    menu: data.menu,
    schedule: {
      hasEndDate: !!schedule.end,
      startTime: (schedule.times && schedule.times[0]) || null,
      isAllDay: !schedule.durationUnit,
      repeat: {},
    },
    is: data.is,
  };

  const { repeat } = menuEvent.schedule;
  if (schedule.every && schedule.every.week) repeat.frequency = schedule.every.week;
  if (repeat.frequency) {
    repeat.days = {};
    schedule.dayOfWeek.forEach((d) => {
      repeat.days[d] = true;
    });
  }
  // this is to determine the repeat type
  const { year, month, dayOfMonth, dayOfWeek } = schedule;
  if (repeat.frequency)
    repeat.type = getRepeatSelectionLabel({ days: repeat.days, repeat: repeat.frequency });
  else if (year && month && dayOfMonth) repeat.type = RecurrenceTypes.NeverRepeat;
  else if (isEqual(dayOfWeek, [1, 2, 3, 4, 5])) repeat.type = RecurrenceTypes.Weekday;
  else repeat.type = RecurrenceTypes.Daily;

  if (!menuEvent.schedule.isAllDay) {
    const hours = Math.floor(schedule.duration / 60);
    const minutes = schedule.duration % 60;
    const startTimes = schedule.times[0].split(':').map((t) => parseInt(t, 10));
    const endMinute = (startTimes[1] + minutes) % 60;
    const endHour = startTimes[0] + hours + Math.floor((startTimes[1] + minutes) / 60);
    menuEvent.schedule.endTime = `${endHour < 10 ? '0' : ''}${endHour}:${
      endMinute < 10 ? '0' : ''
    }${endMinute}`;
  }

  if (schedule.start) {
    menuEvent.schedule.startDate = DateTime.fromMillis(schedule.start)
      .setZone(timezone)
      .toFormat('yyyy-LL-dd');
  }
  if (schedule.end) {
    menuEvent.schedule.endDate = DateTime.fromMillis(schedule.end)
      .setZone(timezone)
      .toFormat('yyyy-LL-dd');
  }

  const newEvent = merge({}, getDefaultMenuEvent(), menuEvent);
  return newEvent;
};

const getMinCloseDate = (date) =>
  DateTime.fromISO(date || DateTime.local().toISODate())
    .plus({ days: 1 })
    .toISODate();

const onDateChange = (menuEvent) => {
  menuEvent.showStartDate = false;
  const endDate = DateTime.fromISO(menuEvent.startDate).plus({ days: 1 });
  if (endDate > DateTime.fromISO(menuEvent.endDate)) {
    menuEvent.endDate = endDate.toISODate();
  }
};

export default {
  onDateChange,
  getMinCloseDate,
  convertEventToMenuEvent,
  convertMenuEventToEvent,
  timeRules,
  getRepeatSelectionLabel,
  validDaysCount,
  isValidRecurrence,
  getDuration,
  formatHour,
  getDefaultMenuEvent,
};
